import React from 'react';
import { Router } from '@reach/router';
import Layout from 'components/Layout';
import { SEO } from 'components/Seo';
import unslugify from 'utils/unslugify';
import EventPage from '../components/Event';

const Event = () => {
  return (
    <Layout>
      <Router basepath="/event">
        <EventPage path="/:name/:id" />
      </Router>
    </Layout>
  );
};

export default Event;

export const Head = ({ location, params }) => {
  return (
    <SEO
      title={`Tutadoo | ${
        params && params.length > 0 && params['*'] ? unslugify(params['*'].split('/')[0]) : 'Event'
      }`}
      pathname={location.pathname}
    />
  );
};
